angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionAttendees',{
        templateUrl: './components/sec/annexa-box-object-session-attendees/annexa-box-object-session-attendees.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['$http', 'SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'SecModals', 'AnnexaModalFactory', 'NotificationFactory', 
       	function ($http, SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, SecModals, AnnexaModalFactory, NotificationFactory) {
            //region General
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            var getFilterCall = function () {
                return { session: { id: vm.sessionComponent.session.id } };
            }

            var getFilterCallAux = function () {
                return {};
            }

            var upDownRender = function(data, type, full, meta) {
                var content = '';

                if(meta.row == meta.settings.fnRecordsTotal() -1 ) {
                    content += '<i class="fa fa-chevron-down text-grey" aria-hidden="true"></i>';
                } else {
                    content += '<a href="" ng-click="$ctrl.upDown(' + full.id + ', false)"><i class="fa fa-chevron-down" aria-hidden="true" title="{{ \'global.sec.literals.downOne\' | translate}}"></i><span class="sr-only">{{ \'global.sec.literals.downOne\' | translate}}</span></a>';
                }

                if(meta.row == 0) {
                    content += '<i class="fa fa-chevron-up text-grey"></i>';
                } else {
                    content += '<a href="" ng-click="$ctrl.upDown(' + full.id + ', true)" aria-hidden="true"  title="{{ \'global.sec.literals.upOne\' | translate}}"><i class="fa fa-chevron-up"></i><span class="sr-only">{{ \'global.sec.literals.upOne\' | translate}}</span></a>';
                }

                return content;
            }

            vm.upDown = function(id, up) {
                var direction = '/down'

                if(up) {
                    direction = '/up'
                }

                $http({
                    url: './api/sec/session_attendee/' + id + direction,
                    method: 'GET'
                }).then(function () {
                    vm.tableDefinition.reloadInternalData(false, true);
                })

            }

            var attendeeTypeRender = function(data, type, full, meta) {
                var canEdit = _.contains(['PENDING', 'CONVENED', 'STARTED'], full.session.state);

                var content = '';

                var attendeeTypeAssists = ' fa-circle-o text-grey';
                var attendeeTypeNotAssists = ' fa-circle-o text-grey';
                var attendeeTypeExcuseAbsence = ' fa-circle-o text-grey';

                switch (data) {
                    case 'ASSISTS':
                        attendeeTypeAssists = ' fa-dot-circle-o text-success';
                        break;
                    case 'NOT_ASSISTS':
                        attendeeTypeNotAssists = ' fa-dot-circle-o text-danger';
                        break;
                    case 'EXCUSE_ABSENCE':
                        attendeeTypeExcuseAbsence = ' fa-dot-circle-o text-amber';
                        break;
                }

                content += '<div class="label label-pill no-bg b-all">';
                content += canEdit ? '<a href="" ng-click="$ctrl.attendeeType(' + full.id + ',\'ASSISTS\')" class="inline " title="{{ \'global.sec.literals.attendeeYes\' | translate}}">' : '';
                content += '            <i class="fa ' + attendeeTypeAssists + ' m-r-sm" aria-label="{{ \'global.sec.literals.attendeeYes\' | translate}}" title="{{ \'global.sec.literals.attendeeYes\' | translate}}"></i>';
                content += canEdit ? '</a>' : '';
                content += canEdit ? '<a href="" ng-click="$ctrl.attendeeType(' + full.id + ',\'EXCUSE_ABSENCE\')"  class="inline " title="{{ \'global.sec.literals.attendeeExcuse\' | translate}}">' : '';
                content += '            <i class="fa ' + attendeeTypeExcuseAbsence + '" aria-label="{{ \'global.sec.literals.attendeeExcuse\' | translate}}" title="{{ \'global.sec.literals.attendeeExcuse\' | translate}}"></i>';
                content += canEdit ? '</a>' : '';
                content += canEdit ? '<a href="" ng-click="$ctrl.attendeeType(' + full.id + ',\'NOT_ASSISTS\')"  class="inline " title="{{ \'global.sec.literals.attendeeNo\' | translate}}">' : '';
                content += '            <i class="fa ' + attendeeTypeNotAssists + ' m-l-sm" aria-label="{{ \'global.sec.literals.attendeeNo\' | translate}}" title="{{ \'global.sec.literals.attendeeNo\' | translate}}"></i>';
                content += canEdit ? '</a>' : '';
                content += '</div>'; // /label

                return content;
            }

            vm.attendeeType = function (id, type) {
                $http({
                    url: './api/sec/session_attendee/type/' + id + '/' + type,
                    method: 'GET'
                }).then(function () {
                    if(vm.sessionComponent.session.attendees && vm.sessionComponent.session.attendees.length > 0){
                        var attendeeAux = $linq(vm.sessionComponent.session.attendees).singleOrDefault(undefined, "x => x.id == " + id);
                        if (attendeeAux) {
                            attendeeAux.attendeeType = type;
                        }
                    }
                    vm.tableDefinition.reloadInternalData(false, true);
                })
            }

            var attendeeTypeHeader = function () {
                var content = '';
                content += '<div class="label-group"><span class="label green" title="'+$filter('translate')('global.sec.literals.attendeeYes')+'">' + $filter('translate')('global.literals.yes') + '</span> <span class="label amber" title="'+$filter('translate')('global.sec.literals.attendeeExcuse')+'">Ex</span> <span class="label red" title="'+$filter('translate')('global.sec.literals.attendeeNo')+'">' + $filter('translate')('global.literals.no') + '</span></div>';

                return content;
            }

            var govermentTeamRender = function(data, type, full, meta) {
                var content = '';

                if(data && data == true) {
                    content = '<i class="m-l-xs fa fa-star text-info"></i>';
                } else {
                    content = '<i class="m-l-xs fa fa-star-o text-grey"></i>';
                }

                return content;
            }

            var voteRender = function(data, type, full, meta) {
                var content = '';

                if(data && data == true) {
                    content = '<i class="material-icons text-success">turned_in</i>';
                } else {
                    content = '<i class="material-icons text-grey-300">turned_in_not</i>';
                }

                return content;
            }

            var conveneNotificationRender = function(data, type, full, meta) {
                var content = '';

                if(data && data.id) {
                	var status = $linq(NotificationFactory.notificationGlobalStatus).firstOrDefault(undefined, "x=>x.id == '"+data.globalStatus+"'");
                	if(status){
                		content = '<a href="" ng-click="$ctrl.showNotificationModal('+data.id+')" class="text-info linkNoStyle"> <i class="material-icons '+status.class+'" title="'+status.name+'">notifications</i></a>';
                	}else {
                		content = '<i class="material-icons text-danger">notifications_off</i>';                		
                	}
                } else {
                    content = '<i class="material-icons text-danger">notifications_off</i>';
                }
                return content;
            }

            vm.showNotificationModal = function (notificationId) {
                RestService.findOne('Notification', notificationId).then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                    AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                }).catch(function (error) {
                	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                });
                
            };
            
            this.$onInit = function () {
                var columns = [
                    { id: 'orderView', visible: false}
                ];
                
                if(vm.sessionComponent.canEdit) {
                    columns.push({ id: 'orderView', title: '', render: upDownRender, sortable: false, className: 'session-attendee-orderView' });
                }
                
                columns.push({ id: 'attendee', column: new TextColumn($filter, 'global.sec.literals.attendee'), sortable: false });

                if(vm.sessionComponent.session.state != 'PENDING') {
                    columns.push({ id: 'attendeeType', title: attendeeTypeHeader(), render: attendeeTypeRender, sortable: false, className: 'session-attendee-attendeeType text-center' });
                }

                columns.push(
                    { id: 'memberRol', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.rol', Language.getActiveColumn()), sortable: false },
                    { id: 'politicalParty', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.politicalParty', Language.getActiveColumn()), sortable: false },
                    { id: 'govermmentTeam', title: $filter('translate')('global.sec.literals.gov'), render: govermentTeamRender, sortable: false, className: 'session-attendee-govermmentTeam' },
                    { id: 'vote', title: $filter('translate')('global.sec.literals.vote'), render: voteRender, sortable: false, className: 'session-attendee-vote' },
                    { id: 'memberPosition', column:  new TextColumn($filter, 'global.literals.position'), sortable: false }
                );
                
                if(vm.sessionComponent.session.state != 'PENDING') {
                    columns.push({ id: 'conveneNotification', title: $filter('translate')('global.literals.notificated'), render: conveneNotificationRender, sortable: false, className: 'session-attendee-conveneNotification'});
                }
                if(vm.sessionComponent.canEdit) {
                	columns.push({ id: 'id', column: 
                		new ActionsColumnAttendees($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', '$ctrl.viewAttendee([data])', 'fa-eye'), [
                        new ActionButton('global.literals.remove', '$ctrl.deleteAttendee([data])')
                    ])
                	, sortable: false, className: 'session-attendee-actions' });
                }else {
                	columns.push({ id: 'id', column: 
                		new ActionsColumnAttendees($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', '$ctrl.viewAttendee([data])', 'fa-eye'), [])
                	, sortable: false, className: 'session-attendee-actions' });
                }

                vm.tableDefinition = {
                    id: 'tableSessionAttendees',
                    origin: 'sec',
                    objectType: 'SessionAttendee',
                    sortName: 'orderView',
                    sort: [[0, 'asc']],
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: columns,
                    containerScope: $scope
                };
            }

            vm.newAttendee = function() {
                var modal = angular.copy(SecModals.sessionAttendeeNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.row1 = {};
                modal.annexaFormly.options = {};

                AnnexaFormlyFactory.showModal('modalNewSessionAttende', modal, vm.saveSessionAttende, false);
            }

            vm.saveSessionAttende = function (sessionAttendee) {
                var modal = this;
                
                var model = {
                    session: { id: vm.sessionComponent.session.id },
                    user: null,
                    memberRol: (($rootScope.app.configuration.sec_organ_member_rols.extern)?{id:$rootScope.app.configuration.sec_organ_member_rols.extern}:null),
                    memberPosition: sessionAttendee.annexaFormly.model.row1.memberPosition ? sessionAttendee.annexaFormly.model.row1.memberPosition : null,
                    politicalParty: null,
                    govermmentTeam: false,
                    attendee: sessionAttendee.annexaFormly.model.row1.attendee ? sessionAttendee.annexaFormly.model.row1.attendee : null
                }

                RestService.insert('./api/sec/session_attendee', model)
                    .then(function() {
                        vm.tableDefinition.reloadInternalData(false, true);
                        modal.close();
                    }).catch(function (error) {
                        modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                });
            }

            vm.deleteAttendee = function (id) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveAttendeeTitle', 'global.sec.literals.confirmRemoveAttendeeBody').then(function (dataAux) {
	            	SecFactory.deleteSessionAttendee(id).then(function (data) {
                        vm.tableDefinition.reloadInternalData(false, true);
                    }).catch(function (error) {
                        //Empty
                    });
                }).catch(function (data) {
	                    //Empty
	            });

            }

            vm.viewAttendee = function (id) {
                RestService.findOne('SessionAttendee', id)
                    .then(function (data) {
                        var decodedData = JSOG.decode(data.data);

                        var modal = angular.copy(SecModals.sessionAttendeeEdit);
                        modal.data = decodedData;
                        modal.alerts = [];
                        modal.languageColumn = Language.getActiveColumn();
                        modal.updateAttendee = vm.updateAttendee;
                        modal.isMember = ((decodedData.member && decodedData.member.id)?true:false);
                        modal.canEdit = !vm.sessionComponent.canEditSessionRealStartDate
                        if(decodedData.member && decodedData.member.id){
                        	modal.organMemberRoles = $linq(SecFactory.organMemberRols).where("x => x.id != "+$rootScope.app.configuration.sec_organ_member_rols.extern).toArray();
                        }else{
                        	modal.organMemberRoles = $linq(SecFactory.organMemberRols).where("x => x.id == "+$rootScope.app.configuration.sec_organ_member_rols.extern).toArray()
                        }
                        AnnexaModalFactory.showModal('modalSessionAttendeeEdit', modal);
                    });
            }
            
            vm.updateAttendee = function (val, prop) {
                var model = this.data;
                var propAux = undefined;
            	if(model.memberRol.id == $rootScope.app.configuration.sec_organ_member_rols.president){
            		propAux = 'president';
            	}else if(model.memberRol.id == $rootScope.app.configuration.sec_organ_member_rols.secretary){
            		propAux = 'secretary';
            	}
                model[prop] = val;

                RestService.update('./api/sec/session_attendee/' + model.id, model)
                    .then(function (data) {
                        vm.tableDefinition.reloadInternalData(false, true);
                        if(propAux){
                        	$rootScope.$broadcast('annexaBoxUpdateAttendeeSession', {origin: 'annexa-box-object-session', prop:propAux, value:data});
                        }
                    }).catch(function () {
                        //Empty
                });
            }

            
            $scope.$on('annexaBoxUpdateAttendeeSession', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-session' && args.prop && args.value) {
        	       if(args.prop == 'president' || args.prop == 'secretary'){
        	    	   vm.tableDefinition.reloadInternalData(false, true);
        	   	   }
        	    }
            });
            
            
            $scope.$on('sendConvene', function (event, args) {
                vm.tableDefinition.reloadInternalData(false, true);
            });
            
        }]
    })
