angular
    .module('annexaApp')
    .component('annexaBoxInternalCommunication',{
        templateUrl: './components/sec/annexa-box-internal-communication/annexa-box-internal-communication.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.internalCommunicationProfiles = GlobalDataFactory.allProfiles;
          
            vm.proposalInternalCommunicationProfiles = [];
        	
        	if (vm.content && vm.content.internalCommunications) {
        		vm.proposalInternalCommunicationProfiles = $linq(angular.copy(vm.content.internalCommunications)).select("x => x.profile").toArray();
        	}
            
            vm.printInternalCommunicationProfiles = function() {
    			var content = '';
    			
    			if(vm.proposalInternalCommunicationProfiles && vm.proposalInternalCommunicationProfiles.length > 0) {
                    _.forEach(vm.proposalInternalCommunicationProfiles, function(proposalInternalCommunicationProfile){
                    	content += ((content == '')?'':', ') + proposalInternalCommunicationProfile[vm.languageColumn];
                    });
                }
    			return content;
            }

            vm.updateProposalInternalCommunication = function(val, prop) {
            	$rootScope.$broadcast('annexaBoxInternalCommunicationAdd', {internalCommunication: val, origin: vm.origin});	
            }

            this.$onInit = function () {
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.internalCommunication';
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            canEdit: '='
        }
    })