angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionBasicInfo',{
        templateUrl: './components/sec/annexa-box-object-session-basic-info/annexa-box-object-session-basic-info.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService) {
            //region General
            var vm = this;
            vm.president = {user: undefined};
            vm.secretary = {user: undefined};
            
            vm.languageColumn = Language.getActiveColumn();
            vm.meetingLocations = SecFactory.meetingLocations;
            vm.archiveClassifications = GlobalDataFactory.archiveClassifications;
            vm.presidentPositions = [];

            vm.secretaryPositions = [];
            
            vm.fieldsInfBasicDefinition = [];
            
            vm.fieldsHistoricDefinition = [];

            vm.getAttendee = function(attendee){
            	var content = '';
            	if(attendee && attendee.attendee){
            		content = attendee.attendee;
            	}
            	return content;
            }
            
            vm.calculateDuration = function(){
            	if(vm.sessionComponent.session.realStartSessionDate && vm.sessionComponent.session.realFinishSessionDate) {
                    var startDate = new Date(vm.sessionComponent.session.realStartSessionDate);
                    var finishDate = new Date(vm.sessionComponent.session.realFinishSessionDate);

                    vm.sessionComponent.session.duration = HelperService.diffDates(startDate, finishDate, 'global.literals.days');
                }	
            }
            
            this.$onInit = function() {
            	vm.memberUsers = $linq(angular.copy(vm.sessionComponent.session.attendees)).where("x => x.user && x.user.id").toArray();
            	
            	vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'sessionDateFirstConvene', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.sec.literals.sessionFirstConvenDate', isReadOnly:vm.sessionComponent.canEdit });
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'sessionDateFirstConvene', fieldType: 'time', colClass: 'col-sm-6 isTimeInput', required: true, label: 'global.sec.literals.sessionFirstConvenHour', isReadOnly:vm.sessionComponent.canEdit });
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'sessionDateSecondConvene', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.sec.literals.sessionSecondConvenDate', isReadOnly:vm.sessionComponent.canEdit});
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'sessionDateSecondConvene', fieldType: 'time', colClass: 'col-sm-6 isTimeInput', required: true, label: 'global.sec.literals.sessionSecondConvenHour', isReadOnly:vm.sessionComponent.canEdit });
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'meetingLocation', fieldType: 'select', data: vm.meetingLocations, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.meetingLocation', isReadOnly:vm.sessionComponent.canEdit  });
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'archiveClassificationAux', fieldType: 'select-tree', data: vm.archiveClassifications, colClass: 'col-sm-12', required: false, label: 'global.literals.classificationBox', isReadOnly:vm.sessionComponent.canEdit  });
				vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'observations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations', isReadOnly:vm.sessionComponent.isNotFinalizedSession });
                
				
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'conveneDate', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.sec.literals.dateTimeConveneDate', isReadOnly:false});
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'conveneDate', fieldType: 'time', colClass: 'col-sm-6 isTimeInput', required: true, label: 'global.sec.literals.dateTimeConveneHour', isReadOnly:false });
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'realStartSessionDate', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.sec.literals.realStartSessionDateDate', isReadOnly:vm.sessionComponent.canEditSessionRealStartDate});
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'realStartSessionDate', fieldType: 'time', colClass: 'col-sm-6 isTimeInput', required: true, label: 'global.sec.literals.realStartSessionDateHour', isReadOnly: vm.sessionComponent.canEditSessionRealStartDate});
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'realFinishSessionDate', fieldType: 'date', colClass: 'col-sm-6', required: true, label: 'global.sec.literals.realFinishSessionDateDate',  isReadOnly:(vm.sessionComponent.canEditSessionRealEndSession && vm.sessionComponent.session.realFinishSessionDate != null && vm.sessionComponent.session.realFinishSessionDate != undefined)});
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'realFinishSessionDate', fieldType: 'time', colClass: 'col-sm-6 isTimeInput', required: true, label: 'global.sec.literals.realFinishSessionDateHour', isReadOnly:(vm.sessionComponent.canEditSessionRealEndSession && vm.sessionComponent.session.realFinishSessionDate != null && vm.sessionComponent.session.realFinishSessionDate != undefined)});
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'duration', fieldType: 'text', colClass: 'col-sm-12', label: 'global.literals.duration', isReadOnly:false });
                vm.fieldsHistoricDefinition.push({ type: 'field', id: 'sessionTransferDate', fieldType: 'datetime', colClass: 'col-sm-6', label: ((vm.sessionComponent.session.organ.resolutionType == 'OPINION')?'global.sec.literals.sessionRaiseDate':'global.sec.literals.sessionTransferDate'),  isReadOnly:false});
                vm.calculateDuration();
            }
            
            $scope.$on('annexaBoxUpdateSession', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-session' && (args.prop == 'realStartSessionDate' || args.prop == 'realFinishSessionDate')) {
                	vm.calculateDuration();
                }
            });
            
            $scope.$on('updateEndSessionOk', function(event, args){
            	vm.calculateDuration();
            });
            $scope.$on('sessionModifyDates', function(event, args){
            	vm.calculateDuration();
            });
        	$scope.$on('sessionGenerateConvene', function(event, args){
            	vm.calculateDuration();
            });
        	$scope.$on('sessionUpdatedConvene', function(event, args){
        		vm.calculateDuration();
            });
			$scope.$on('sessionGenerateAgreements', function(event, args){
				vm.calculateDuration();   	
            });
			$scope.$on('sessionGenerateAct', function(event, args){
				vm.calculateDuration();
			});
			$scope.$on('sessionGenerateCertificates', function(event, args){
				vm.calculateDuration();
			});
			$scope.$on('sessionPointOrderAdded', function(event, args){
				vm.calculateDuration();
			});
        }]
    })
