/**
 * Created by osirvent on 15/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaOrganAgendaPointTypes', {
        templateUrl: './components/sec/annexa-organ-agenda-point-types/annexa-organ-agenda-point-types.html',
        controller: ['Language', '$filter', '$scope', '$rootScope', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'CommonService', 'globalModals', function (Language, $filter, $scope, $rootScope, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, CommonService, globalModals) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.addOrganPoint = function () {
            	var modal = angular.copy(CommonAdminModals.organAgendaPointTypeNew);
                modal.annexaFormly.model = {row_point:{resolutionType:vm.resolutionType}};
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.annexaFormly.model.row_point.isNew = true;
                vm.identificadorAdd = new Date().getTime();
                modal.identificadorAdd = vm.identificadorAdd;
                AnnexaFormlyFactory.showModal('modalNewOrganAgendaPointTypeNew', modal, vm.saveOrganPoint, false);
            }
            
            vm.saveOrganPoint = function(agendaPointType){
            	if(agendaPointType && agendaPointType.annexaFormly && agendaPointType.annexaFormly.model && agendaPointType.annexaFormly.model.row_point){
		        	var pointType = {
	        			language1:((agendaPointType.annexaFormly.model.row_point.language1)?agendaPointType.annexaFormly.model.row_point.language1:undefined),
	        			language2:((agendaPointType.annexaFormly.model.row_point.language2)?agendaPointType.annexaFormly.model.row_point.language2:undefined),
	        			language3:((agendaPointType.annexaFormly.model.row_point.language3)?agendaPointType.annexaFormly.model.row_point.language3:undefined),
	        			type:((agendaPointType.annexaFormly.model.row_point.type)?agendaPointType.annexaFormly.model.row_point.type:undefined),
	        			template:((agendaPointType.annexaFormly.model.row_point.template)?agendaPointType.annexaFormly.model.row_point.template:undefined),
	        			orderView:1
		        	};
		        	var orderView = 1;
		        	if(vm.pointTypes && vm.pointTypes.length > 0){
		        		pointType.orderView = $linq(vm.pointTypes).select("x=>x.orderView").max()+1;
		        	}
		        	
		        	if(!vm.isNew){
		        		if(pointType){
		        			var pointTypeAux = angular.copy(pointType);
		        			pointTypeAux.language1 = ((pointType.language1)?pointType.language1:undefined);
		        			pointTypeAux.language2 = ((pointType.language2)?pointType.language2:undefined);
		        			pointTypeAux.language3 = ((pointType.language3)?pointType.language3:undefined);
		        			pointTypeAux.type = ((pointType.type && pointType.type.id)?pointType.type.id:((pointType.type)?pointType.type:undefined));
		        			pointTypeAux.organ = {id: vm.organId};
		        			pointTypeAux.template = ((pointType.template && pointType.template.id)?{id: pointType.template.id}:undefined);
                    		if(vm.pointTypes && vm.pointTypes.length > 0){
                    			pointTypeAux.orderView = $linq(vm.pointTypes).select("x=>x.orderView").max()+1;
        		        	}else{
        		        		pointTypeAux.orderView = 1;
        		        	}
                    		RestService.insert('./api/sec/organ_agenda_point_type', pointTypeAux).then(function(data) {
                    			pointType.id = data.id;
                    			pointType.organ = data.organ.id;
                    			pointType.type = data.type;
                    			pointType.orderView = data.orderView;
                    			pointType.createdDate = data.createdDate;
                    			if(!vm.pointTypes){
                    				vm.pointTypes = [];
                                }
                    			vm.pointTypes.push(pointType);
                    			agendaPointType.close();
                            }).catch(function() {
                            	agendaPointType.alerts.push("Error");
                            });
        	                
                        }
		        	}else{
		        		vm.pointTypes.push(pointType);
		        		agendaPointType.close();
		        	}
            	}
            }

            vm.editOrganPoint = function (agendaPointType, index) {
            	var modal = angular.copy(CommonAdminModals.organAgendaPointTypeNew);
            	var pointToModify = angular.copy(agendaPointType);
            	if(agendaPointType.type){
            		if(!agendaPointType.type.id){
            			pointToModify.type = {id:agendaPointType.type, name: $filter('translate')('global.sec.literals.resolutionType'+agendaPointType.type)};
            		}
            	}
            	modal.annexaFormly.model = {index:index, row_point: pointToModify};
            	modal.annexaFormly.model.row_point.resolutionType = vm.resolutionType;
            	modal.annexaFormly.model.row_point.isNew = vm.isNew;
                modal.annexaFormly.options = { watchAllExpressions: true };

                AnnexaFormlyFactory.showModal('modalNewOrganAgendaPointTypeNew', modal, vm.updateOrganPoint, false);
            }
            
            vm.updateOrganPoint = function(agendaPointType){
            	if(agendaPointType && agendaPointType.annexaFormly && agendaPointType.annexaFormly.model && agendaPointType.annexaFormly.model.row_point){
		        	var pointType = angular.copy(agendaPointType.annexaFormly.model.row_point);
		        	if(vm.isNew){
		        		pointType.template = ((pointType.template)?pointType.template:undefined);
		        	}
        			if(pointType){
		        		var pointTypeIndex = -1;
		        		if(pointType.id){
		        			pointTypeIndex = $linq(vm.pointTypes).indexOf("x => x.id == "+pointType.id);
		        		}else{
		        			pointTypeIndex = agendaPointType.annexaFormly.model.index;
		        		}
		        		if(pointTypeIndex > -1){
				        	if(!vm.isNew){
				        		if(pointType){
				        			var pointTypeAux = angular.copy(pointType);
				        			pointTypeAux.language1 = ((pointType.language1)?pointType.language1:undefined);
				        			pointTypeAux.language2 = ((pointType.language2)?pointType.language2:undefined);
				        			pointTypeAux.language3 = ((pointType.language3)?pointType.language3:undefined);
				        			pointTypeAux.type = ((pointType.type && pointType.type.id)?pointType.type.id:((pointType.type)?pointType.type:undefined));
				        			pointTypeAux.orderView=((pointType.orderView)?pointType.orderView:-1);
		                    		pointTypeAux.organ = {id: vm.organId};
		                    		pointTypeAux.template = ((pointType.template && pointType.template.id)?{id:pointType.template.id}:((pointType.template)?{id:pointType.template}:undefined));
		                    		
		                    		RestService.update('./api/sec/organ_agenda_point_type/' + pointTypeAux.id, pointTypeAux).then(function (data) {
		                    			if(pointTypeIndex || pointTypeIndex == 0){
			                    			if(vm.pointTypes && vm.pointTypes.length >= pointTypeIndex){
			                    				vm.pointTypes[pointTypeIndex] = pointType;
			                                }
		                    			}
		                    			agendaPointType.close();
		                            }).catch(function () {
		                            	agendaPointType.alerts.push("Error");
		                            });
		                        }
				        	}else{
				        		vm.pointTypes[pointTypeIndex] = pointType;
				        		agendaPointType.close();
				        	}
			        	}
		        	}
            	}
            }
            
            vm.deleteOrganPoint = function (point, index) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveOrganPointTitle', 'global.sec.literals.confirmRemoveOrganPointBody').then(function (dataAux) {
           			if(point){
		        		var pointIndex = -1;
		        		if(point.id){
		        			pointIndex = $linq(vm.pointTypes).indexOf("x => x.id == "+point.id);
		        		}else{
		        			pointIndex = index;
		        		}
		        		if(pointIndex > -1){
				        	if(!vm.isNew){
				        		if(point.id && (pointIndex || pointIndex == 0) && vm.pointTypes && vm.pointTypes.length >= pointIndex){
		                    		var deleteOk = 0;
		                    		if(vm.defaultPoints && vm.defaultPoints.length > 0){
		                    			deleteOk = $linq(vm.defaultPoints).count("x => x.type && x.type.id == "+point.id);
		                    		}
		                    		if(deleteOk == 0){
			                    		RestService.delete('./api/sec/organ_agenda_point_type/' + point.id).then(function (data) {
			                    			vm.pointTypes.splice(pointIndex, 1);
			                            }).catch(function (error) {
			                                console.log(error);
			                            });
		                    		}else{
		                    			DialogsFactory.error($filter('translate')('global.sec.literals.agendaPointTypeHaveDefaultPoints'));
		                    		}
		        	                
		                        }
				        	}else{
				        		vm.pointTypes.splice(pointIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
            	if(!vm.resolutionType){
            		vm.resolutionType = 'AGREEMENT';
            	}
            }
            
        }],
        bindings: {
        	pointTypes: '=',
            isNew: '=',
            resolutionType: '<?',
            defaultPoints: '=?',
            organId:'=?'
        }
    })