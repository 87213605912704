angular
    .module('annexaApp')
    .component('annexaBoxObjectSession',{
        templateUrl: './components/sec/annexa-box-object-session/annexa-box-object-session.html',
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService) {
            //region General
            var vm = this;
            
            vm.isTransfered = false;
            vm.isRaiseOpinion = false;
            
            var calculateConveneFunction = function(){
            	var documentType = $rootScope.app.configuration.sec_document_types["SCO"];
            	if(vm.session && vm.session.documents && documentType){
            		_.forEach(vm.session.documents, function(document){
            			if(!document.added && document.document && document.document.docStatus && document.document.docStatus != 'REJECTED' && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
            				vm.haveConveneDocument = true;
            				vm.conveneDocument = document;
            			}
            		});
            	}
            }
            var calculateCertificatesFunction = function(){
            	var documentType = undefined;
            	if(vm.session.organ.resolutionType == 'OPINION'){
            		documentType = $rootScope.app.configuration.sec_document_types["SDI"];
            	}else{
            		documentType = $rootScope.app.configuration.sec_document_types["SCR"];
            	}
            	if(vm.idsCertificatesDocument){
        			vm.idsCertificatesDocument.length = 0;
        		}else{
        			vm.idsCertificatesDocument = [];
        		}
            	if(vm.session && vm.session.documents && documentType){
            		var spas = 0;
            		var spasOK = 0;
            		_.forEach(vm.session.documents, function(document){
            			if(!document.added && document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
            				spas++;
            				vm.idsCertificatesDocument.push(document.document.id);
            				if(document.document.docStatus && document.document.docStatus == 'SIGNED'){
            					spasOK++;
            				}
            			}
            		});
            		var proposalsVoted = 0;
            		if(vm.session.organ.resolutionType == 'OPINION'){
            			proposalsVoted = $linq(vm.session.executedOrder).count("x => x.proposal && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED'");
            		}else{
            			proposalsVoted = $linq(vm.session.executedOrder).count("x => x.proposal && x.voteState != 'REFUSED' && x.voteState != 'POSTPONED' && x.voteState != 'NO_VOTE'");
            		}	
            		if(spas >= proposalsVoted){
            			vm.haveCertificatesDocument = true;
            		}
            		if(spasOK > 0 && spas == spasOK){
            			vm.haveCertificatesDocumentSigned = true;
            		}
            	}
            }
            var calculateAgreementsFunction = function(){
            	var documentType = $rootScope.app.configuration.sec_document_types["SPA"];
            	if(vm.session && vm.session.documents && documentType){
            		_.forEach(vm.session.documents, function(document){
            			if(!document.added && document.document && document.document.docStatus && document.document.docStatus != 'REJECTED' && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
            				vm.haveAgreementsDocument = true;
            				if(document.document.docStatus == 'SIGNED'){
            					vm.haveAgreementsDocumentSigned = true;
            				}
            			}
            		});
            	}
            }
            var calculateActFunction = function(){
            	var documentType = $rootScope.app.configuration.sec_document_types["SAC"];
            	if(vm.session && vm.session.documents && documentType){
            		_.forEach(vm.session.documents, function(document){
            			if(!document.added && document.document && document.document.docStatus && document.document.docStatus != 'REJECTED' && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
            				vm.haveActDocument = true;
            				if(document.document.docStatus == 'SIGNED'){
            					vm.haveActDocumentSigned = true; 
            				}
            			}
            		});
            	}
            }
            vm.haveCertificatesDocument = false;
            vm.idsCertificatesDocument = [];
            vm.haveCertificatesDocumentSigned = false;
            vm.haveAgreementsDocument = false;
            vm.haveAgreementsDocumentSigned = false;
            vm.haveActDocument = false;
            vm.haveActDocumentSigned = false;
            vm.haveConveneDocument = false;
            vm.conveneDocument = undefined;
            
            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
            vm.canEdit = ((vm.session && vm.session.state && _.contains(['PENDING'],vm.session.state))?true:false);
            vm.isNotFinalizedSession = ((vm.session && vm.session.state && !_.contains(['FINISHED', 'CANCELED'],vm.session.state))?true:false);
            vm.canEndSession = function() {
                var votePoints = $linq(vm.session.executedOrder).where(function(x) {
                    if(!_.contains(['GROUP', 'NOVOTE', 'OPINION'], x.type.type)) {
                        return true;
                    }
                    return false;
                }).toArray();

                return $linq(votePoints).count("x => x.voteState == 'NO_VOTE'") == 0 && vm.session && vm.session.state && _.contains(['STARTED'],vm.session.state);
            }
            
            vm.tabs = [];
            //region Form
                         
            vm.alerts = [];

            $scope.$on('annexaBoxUpdateSateSession', function (event, args) {
	            if(args.origin && args.origin == 'annexa-box-object-session') {
	            	vm.canEdit = ((vm.session && vm.session.state && _.contains(['PENDING'],vm.session.state))?true:false);      
	            	HeaderService.changeState($state.current, true);
	            }
	        });
            //endregion


            this.$onInit = function() {
            	if(vm.session && vm.session.archiveClassification){
            		vm.session.archiveClassificationAux = angular.copy(vm.session.archiveClassification);
            	}
            	calculateConveneFunction();
            	calculateCertificatesFunction();
            	calculateAgreementsFunction();
            	calculateActFunction();
            	vm.canEditSessionRealStartDate = ((vm.session && vm.session.state && _.contains(['STARTED', 'EXECUTED'],vm.session.state))?true:false);
            	vm.canEditSessionRealEndSessionFunction = function() {
                    var votePoints = $linq(vm.session.executedOrder).where(function(x) {
                        if(!_.contains(['GROUP', 'NOVOTE', 'OPINION'], x.type.type)) {
                            return true;
                        }
                        return false;
                    }).toArray();

                    return $linq(votePoints).count("x => x.voteState == 'NO_VOTE'") == 0 && vm.session && vm.session.state && _.contains(['STARTED', 'EXECUTED'],vm.session.state);
                }
            	vm.canEditSessionRealEndSession = vm.canEditSessionRealEndSessionFunction();
            	if(vm.session && vm.session.organ && vm.session.organ.resolutionType != 'OPINION'){
            		vm.isTransfered = ((vm.session.sessionTransferDate)?true:false);
            	}
            	
            	if(vm.session && vm.session.organ && vm.session.organ.resolutionType == 'OPINION'){
            		vm.isRaiseOpinion = ((vm.session.sessionTransferDate)?true:false);
            	}
                vm.tabs.push({ heading: 'global.sec.literals.generalData', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content: '<annexa-box-object-session-basic-info></annexa-box-object-session-basic-info>', select: undefined, deselect: undefined });
        		vm.tabs.push({ heading: 'global.sec.literals.attendees', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content: '<annexa-box-object-session-attendees></annexa-box-object-session-attendees>', select: undefined, deselect: undefined  });
    			vm.tabs.push({ heading: 'global.sec.literals.proposedOrder', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content: '<annexa-box-object-session-proposed-order></annexa-box-object-session-proposed-order>', select: undefined, deselect: undefined });
    			vm.tabs.push({ heading: 'global.sec.literals.executedOrder', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content: '<annexa-box-object-session-executed-order></annexa-box-object-session-executed-order>', select: undefined, deselect: undefined, hide: vm.session.state == 'PENDING' });
    			vm.tabs.push({ heading: 'global.literals.documents', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content:'<annexa-box-object-session-documents></annexa-box-object-session-documents>', select: undefined, deselect: undefined});
    			vm.tabs.push({ heading: 'global.sec.literals.transfers', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal', content:'<annexa-box-object-session-transfers></annexa-box-object-session-transfers>', select: undefined, deselect: undefined, hide: (vm.session.organ.resolutionType == 'OPINION' || !vm.isTransfered) });
            }

            //region session Methods
            $scope.goBack = function(){
                $state.transitionTo('annexa.sec.sessions');
            };
            
            vm.createEni = function(){
                DialogsFactory.error($filter('translate')('global.sec.errors.cantCreateEniDocument'));
            }
            
            vm.updateSession = function(val, prop) {
            	if(_.contains(['president','secretary'], prop)){
            		var indexOfPresident = $linq(vm.session.attendees).indexOf("x => x.memberRol.id == " + $rootScope.app.configuration.sec_organ_member_rols.president);
                    var indexOfSecretary = $linq(vm.session.attendees).indexOf("x => x.memberRol.id == " + $rootScope.app.configuration.sec_organ_member_rols.secretary);
                    var indexOfActual = $linq(vm.session.attendees).indexOf("x => x.id == " + val.id);
            		var idBeforeAttendee = undefined;
                    var isPresident = false;
                    switch (prop) {
	                    case 'president':
	                    	if(indexOfPresident != -1){
	                    		idBeforeAttendee = vm.session.attendees[indexOfPresident].id;
	                    	}else{
	                    		idBeforeAttendee = -1;
	                    	}
	                    	val.memberRol = {id: $rootScope.app.configuration.sec_organ_member_rols.president}
	                        isPresident = true;
	                        break;
	                    case 'secretary':
	                    	if(indexOfSecretary != -1){
	                    		idBeforeAttendee = vm.session.attendees[indexOfSecretary].id;
		                    }else{
		                		idBeforeAttendee = -1;
		                	}
		                	val.memberRol = {id: $rootScope.app.configuration.sec_organ_member_rols.secretary}
	                        isPresident = false;
	                        break;
                	}
        			RestService.update('./api/sec/session_attendee/' + val.id+'/position/'+idBeforeAttendee, val).then(function (data) {
                		vm.canEdit = ((vm.session && vm.session.state && _.contains(['PENDING'],vm.session.state))?true:false);
                		if(isPresident && vm.session.attendees[indexOfPresident]){
                			vm.session.attendees[indexOfPresident].memberRol = {id:$rootScope.app.configuration.sec_organ_member_rols.notAssign};
                		}else if(vm.session.attendees[indexOfSecretary]){
                			vm.session.attendees[indexOfSecretary].memberRol = {id:$rootScope.app.configuration.sec_organ_member_rols.notAssign};
                		}
                		vm.session.attendees[indexOfActual].memberRol = val.memberRol;
                       	$rootScope.$broadcast('annexaBoxUpdateAttendeeSession', {origin: 'annexa-box-object-session', prop:prop, value:data, indexChanged:((isPresident)?indexOfPresident:indexOfSecretary)});
                    }).catch(function (error) {
                        console.error(error);
                    });
                }else{
                	if(prop == 'sessionDateFirstConvene'){
                		if(vm.session.sessionDateSecondConvene && val && val > vm.session.sessionDateSecondConvene) {
                            return $filter('translate')('global.sec.literals.firstSessionAfterSecondSession');
                        }
                		vm.session[prop] = val;
                	}else if(prop == 'sessionDateSecondConvene'){
                		if(vm.session.sessionDateFirstConvene && val && vm.session.sessionDateFirstConvene > val) {
                            return $filter('translate')('global.sec.literals.firstSessionAfterSecondSession');
                        }
                		vm.session[prop] = val;
                	}else if(prop == 'archiveClassificationAux'){
                		if(val && val.$selected && val.$selected.id){
                			vm.session.archiveClassification = {id:val.$selected.id};
                        }else{
                        	vm.session.archiveClassification = undefined;
                        }
                	}else{
                		vm.session[prop] = val;
                	}
                	
	                RestService.update('./api/sec/session/' + vm.session.id, vm.session).then(function (data) {
                		vm.canEdit = ((vm.session && vm.session.state && _.contains(['PENDING'],vm.session.state))?true:false);      
                       	$rootScope.$broadcast('annexaBoxUpdateSession', {origin: 'annexa-box-object-session', prop:prop});
                    }).catch(function (error) {
                        console.error(error);
                    });
                }
           }
           //endregion
           
           $scope.$on('sessionUpdatedConvene', function(event, args){calculateConveneFunction();});
           $scope.$on('sessionGenerateConvene', function(event, args){calculateConveneFunction();});
           $scope.$on('sendConvene', function(event, args){calculateConveneFunction();});
           $scope.$on('sessionGenerateAct', function(event, args){calculateActFunction();});
           $scope.$on('sessionGenerateAgreements', function(event, args){calculateAgreementsFunction();});
           $scope.$on('sessionGenerateCertificates', function(event, args){calculateCertificatesFunction();});
           $scope.$on('sessionModifyStatusOfSession', function(event, args){
        	  if(vm.session && vm.session.state && _.contains(['STARTED', 'EXECUTED', 'FINISHED'],vm.session.state)){
        		  vm.canEditSessionRealStartDate = true;
        	  }else{
        		  vm.canEditSessionRealStartDate = false;
        	  } 
        	  vm.canEditSessionRealEndSession = vm.canEditSessionRealEndSessionFunction();
        	  calculateCertificatesFunction();
	   		  calculateAgreementsFunction();
	   		  calculateActFunction();
           });
           $scope.$on('AnnexaReloadInternalDataFinished', function(event, args){ 
        	   if(args && args.table && args.table == 'tableDocs' && args.objectType && args.objectType == 'SessionDocument'){
        		   calculateCertificatesFunction();
        		   calculateAgreementsFunction();
        		   calculateActFunction();
        	   }
           }); 
        }],
        bindings: {
            redirectToList: '=',
            session: '=?',
            preloadBoxes: '=?',
        }
    })
    .component('annexaBoxObjectSessionHeader',{
        templateUrl: './components/sec/annexa-box-object-session/annexa-box-object-session-header.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller:['$rootScope', '$filter', 'Language', 'SecFactory', '$scope', function($rootScope, $filter, Language, SecFactory, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaBoxUpdateSession', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-session') {
                	vm.state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
                	if(vm.sessionComponent.session && vm.sessionComponent.session.state){
                		vm.state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '"+vm.sessionComponent.session.state+"'");
               		}
                	vm.canEdit = ((vm.sessionComponent.session && vm.sessionComponent.session.state && _.contains(['PENDING'],vm.sessionComponent.session.state))?true:false);      
                }
            });
            
            this.$onInit = function() {
            	vm.state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(vm.sessionComponent.session && vm.sessionComponent.session.state){
            		vm.state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '"+vm.sessionComponent.session.state+"'");
           		}
            	vm.canEdit = ((vm.sessionComponent.session && vm.sessionComponent.session.state && _.contains(['PENDING'],vm.sessionComponent.session.state))?true:false);                
            }
        }]
    })
